::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.min__table-height {
  min-height: 457px !important;
}

.modal-content {
  z-index: 120001;
}

//.min__table-height__inner {
//  min-height: 300px !important;
//}

.height-50 {
  height: 50px !important;
}

.position__rel {
  position: relative !important;
}

.position__abs-center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.card-body > .list-group > .list-group-item {
  padding: 0 !important;

  margin-bottom: 5px !important;
}

.card-body > .list-group > .list-group-item {
  background: #f8f9fa !important;
}

.custom-light {
  //background: transparent !important;
}

.h800 {
  height: 800px;
}

.w_120 {
  width: 120% !important;
}

.content-page {
  .content {
    min-height: 45vh !important;

    .container-fluid {
      min-height: 45vh !important;

      .min__height-100 {
        min-height: 45vh !important;
        position: relative !important;

        .move__top {
          position: absolute !important;
          //transform: rotate(-90deg) !important;
          //top: 35px !important;
          top: 0 !important;
          //left: -50px !important;
          left: 0 !important;
          z-index: 1000 !important;
          background: transparent !important;
          border: none !important;

          .start__end-parent {
            display: flex !important;

            .start-visit {
              background: $primary !important;
              border: none !important;
              padding: 2px 10px !important;
            }
            .end-visit {
              padding: 2px 10px !important;
              background: purple !important;
              border: none !important;
            }
          }
        }

        .iframe-parent {
          position: relative !important;
          min-height: 84vh !important;

          embed, iframe, object {
            background: transparent !important;
            position: absolute !important;
            top: 0 !important;
            left: 0 !important;
            width: 100% !important;
            height: 100% !important;
            z-index: 99 !important;
          }

          .carousel {
            width: 100% !important;
            min-height: 80vh !important;

            .carousel-control-prev, .carousel-control-next {
              top: unset !important;
              bottom: -26px !important;
              width: 10% !important;
              padding: 5px !important;
              background: $primary !important;
              color: #fff !important;
              z-index: 11111 !important;
            }

            .carousel-indicators {
              z-index: 1111 !important;
              bottom: -40px !important;

              & [data-bs-target] {
                width: 20px !important;
                height: 20px !important;
                border-radius: 50% !important;
                border: 0 !important;
                background: $primary !important;
              }
            }

            .carousel-inner {
              min-height: 78vh !important;
              width: 100% !important;

              .carousel-item {
                width: 100% !important;
                min-height: 78vh;

                embed, iframe, object {
                  background: transparent !important;
                  position: absolute !important;
                  top: 0 !important;
                  left: 0 !important;
                  width: 100% !important;
                  height: 100% !important;
                  z-index: 99 !important;
                }

                .carousel-caption {
                  padding: 4px !important;
                  top: 0 !important;
                  bottom: unset !important;
                  left: unset !important;
                  right: 0 !important;
                  //background: #2ba8dc !important;
                  //background: $primary !important;
                  background: #1b1b1bd9 !important;
                  color: #fff !important;
                  border-bottom-left-radius: 2px !important;
                  z-index: 1000 !important;

                  h3 {
                    font-size: 12px;
                    font-weight: bold;
                    margin: 0 !important;
                    color: #fff !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.single__visit {
  .modal {
    .modal-dialog {
      min-width: 100vw !important;
      max-width: 100vw !important;
      width: 100vw !important;
      margin-bottom: 0 !important;

      .modal-content {
        width: 100vw !important;
        padding: 0 !important;

        .modal-body {
          width: 100vw !important;
          padding: 0 !important;
        }
      }
    }
  }
}


.absolut__width-115 {
  width: 115px !important;
}

.custom__border {
  position: relative;

  &:before {
    content : "";
    position: absolute;
    right: 0;
    bottom: 0;
    height: 1px;
    width: 85%;
    border-bottom: 1px dashed #ced3e5 !important;
  }
}

.position__absolute-custom {
  position: absolute !important;
  bottom: 0 !important;
  right: 50px !important;
}

@media (max-width: 768px){
  .position__absolute-custom {
    right: unset !important;
    left: 20px !important;
  }

}

.min__height-80 {
  min-height: 40vh !important;
}

@media (min-width: 992px) {
  .width__lg-60 {
    width: 60% !important;
  }
}

//.custom__table-main tbody tr {
//  height: 50px !important;
//}
.avatar-xxl:hover, .avatar-lg:hover {
  cursor: pointer !important;
}

.custom__yandex-map {
  width: 100%;
  min-height: 60vh;
}

@media (max-width: 767px) {
  .custom__yandex-map {
    min-height: 40vh;
  }

  .min__table-height {
    min-height: 500px !important;
  }
}


.custom__week-tab {
  display: flex !important;
  justify-content: space-between !important;
  flex-wrap: wrap !important;
  border-bottom: unset !important;
  //background: red !important;
  .nav-item {
    flex: 1 !important;

    .nav-link {
      width: 100% !important;
      border-radius: unset !important;
      background: #BCCEF8 !important;
      border-right: 1px solid #fff !important;
      border-left: 1px solid #fff !important;
      color: #FFFFFF !important;
      margin-bottom: 5px !important;

      &.active {
        background: #98A8F8 !important;
        color: #fff !important;
      }
    }
  }
}

.visit__user-tab {

  .nav-tabs {
    border-bottom: none !important;

    .nav-item {
      .nav-link {
        background: #fff !important;
        //background: #FBF7F0 !important;
        //background: #B1B2FF !important;
        color: #8AAAE5 !important;
        border: 1px solid #8AAAE5;
        margin: 2px !important;
        border-top-left-radius: unset !important;
        border-top-right-radius: unset !important;
        min-width: 140px !important;

        &.active {
          background: #AAC4FF !important;
          color: #fff !important;
        }
      }
    }
  }
}

.visit__user-tab-1 {

  .nav-tabs {
    border-bottom: none !important;

    .nav-item {
      .nav-link {
        background: #fff !important;
        color: #FA7070 !important;
        border: 1px solid #FA7070;
        margin: 2px !important;
        padding: .2rem 1rem !important;
        border-top-left-radius: unset !important;
        border-top-right-radius: unset !important;
        min-width: unset !important;

        &.active {
          background: #FA7070 !important;
          color: #fff !important;
        }
      }
    }
  }
}

.visit__user-tab-2 {

  .nav-tabs {
    border-bottom: none !important;

    .nav-item {
      .nav-link {
        background: #fff !important;
        color: #EE4E34 !important;
        border: 1px solid #EE4E34;
        margin: 2px !important;
        padding: .2rem 1rem !important;
        border-top-left-radius: unset !important;
        border-top-right-radius: unset !important;
        min-width: unset !important;

        &.active {
          background: #EE4E34 !important;
          color: #fff !important;
        }
      }
    }
  }
}

.custom__border-left {
  border-left: 2px solid #AAC4FF !important;
}

.min__width-200 {
  min-width: 200px !important;
}

.main__star {
  color: red !important;
}

.custom__btn-width {
  width: 22px !important;
  height: 22px !important;
}

.border_bottom-cus {
  border-bottom: 1px solid #c2c2c2 !important;
}

.inside__custom-btn button {
  background: #AAC4FF !important;
  border: none !important;
  color: #fff !important;
}